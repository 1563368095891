<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
            <v-card max-width="380" class="mx-auto">
                <v-layout align-center justify-center column class="pt-6">
                    <v-img
                        :src="require('@/assets/images/logo.svg')"
                        width="144px"
                        height="144px"
                        style="filter: contrast(0.8)"
                    ></v-img>
                    <div class="display-1 ma-3 text-center">
                        City of Stonnington
                    </div>
                    <div class="subtitle-1">Sign into your account</div>
                </v-layout>
                <div class="pa-6">
                    <v-tabs v-model="tab" class="no-tab-bar">
                        <v-tabs-items v-model="tab">
                            <v-tab-item value="email-tab">
                                <v-form ref="form" @submit.prevent="checkemail">
                                    <v-text-field
                                        autofocus
                                        outlined
                                        clearable
                                        required
                                        v-model="form.email"
                                        label="Email"
                                        :error-messages="
                                            form.$getError('email')
                                        "
                                    ></v-text-field>
                                    <v-btn
                                        block
                                        large
                                        color="primary"
                                        type="submit"
                                        :loading="form.$busy"
                                        >Submit</v-btn
                                    >
                                </v-form>
                            </v-tab-item>
                            <v-tab-item value="password-tab">
                                <div class="text-center mb-8">
                                    <div v-if="form.email">
                                        <v-chip
                                            close
                                            @click:close="notYou"
                                            outlined
                                        >
                                            <v-avatar left>
                                                <img
                                                    :src="
                                                        require('@/assets/images/default-profile.png')
                                                    "
                                                    alt=""
                                                />
                                            </v-avatar>
                                            {{ form.email }}
                                        </v-chip>
                                    </div>
                                </div>
                                <v-form ref="form" @submit.prevent="signIn">
                                    <v-text-field
                                        autofocus
                                        outlined
                                        required
                                        type="password"
                                        label="Password"
                                        v-model="form.password"
                                        :error-messages="
                                            form.$getError('password')
                                        "
                                    ></v-text-field>
                                    <v-btn
                                        block
                                        large
                                        color="primary"
                                        type="submit"
                                        :loading="form.$busy"
                                        >Sign in</v-btn
                                    >
                                </v-form>
                                <v-layout class="mt-4">
                                    <v-btn
                                        text
                                        color="primary"
                                        block
                                        v-bind:href="
                                            'mailto:digital@stonnington.vic.gov.au?subject=Reset Password'
                                        "
                                        >Forgot password ?</v-btn
                                    >
                                </v-layout>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                </div>
            </v-card>
            <br />
            <v-alert
                color="warning"
                dismissible
                prominent
                type="error"
                :value="disabledAlert"
                @input="alertOnClose"
                >Your account has been disabled. Please contact
                administrator.</v-alert
            >
            <v-dialog persistent v-model="changePasswordRequired" width="500">
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Password Change is Required
                    </v-card-title>
                    <v-card-text>
                        <br />
                        <v-form ref="form" @submit.prevent="changePassword">
                            <label class="text-field-label">Old password</label>
                            <v-text-field
                                flat
                                type="password"
                                outlined
                                solo
                                required
                                class="mt-2"
                                v-model="form.old_password"
                            ></v-text-field>
                            <label class="text-field-label">New password</label>
                            <v-text-field
                                flat
                                outlined
                                solo
                                required
                                class="mt-2"
                                @click:append="
                                    () => (showPassword = !showPassword)
                                "
                                :append-icon="
                                    showPassword ? icons.eyeOn : icons.eyeOff
                                "
                                :type="showPassword ? 'text' : 'password'"
                                v-model="form.new_password"
                                :error-messages="form.$getError('new_password')"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn
                            prominent
                            type="error"
                            @click="cancelChangePassword"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            prominent
                            type="error"
                            @click="changePassword"
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import Form from '@/utils/form'
import { mdiChevronLeft, mdiEye, mdiEyeOff } from '@mdi/js'

export default {
    name: 'LoginPage',
    data() {
        return {
            tab: 'email-tab',
            disabledAlert: false,
            showPassword: false,
            changePasswordRequired: false,
            form: new Form({
                email: '',
                password: ''
            }),
            icons: {
                arrowLeft: mdiChevronLeft,
                eyeOn: mdiEye,
                eyeOff: mdiEyeOff
            },
            snackbar: {
                show: false,
                message: null,
                color: ''
            }
        }
    },

    methods: {
        checkemail() {
            this.form.$clearErrors()
            this.form.$busy = true
            this.$api
                .post('admin/auth/check-email', { email: this.form.email })
                .then(({ data }) => {
                    this.form.$busy = false
                    this.showPasswordTab()
                })
                .catch(err => {
                    this.form.$busy = false
                    if (err.response.status === 404) {
                        this.form.$setErrors({
                            email: err.response.data.message
                        })
                    } else if (err.response.status === 422) {
                        this.form.$setErrors(err.response.data.errors)
                    }
                })
        },

        changePassword() {
            this.$api
                .post('/admin/auth/change-password', this.form)
                .then(res => {
                    this.showSnackbar(
                        'Password successfully changed.',
                        'success'
                    )
                    this.form.$setErrors({})
                    this.changePasswordRequired = false
                    this.$router.replace('/bindays')
                })
                .catch(err => {
                    this.form.$setErrors(err.response.data.errors)
                })
        },

        cancelChangePassword() {
            this.$api.post('/admin/auth/logout').then(() => {
                location.reload()
            })
        },

        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.show = true
            this.snackbar.color = color
        },

        signIn() {
            this.form.$clearErrors()
            this.form.$busy = true
            this.$api
                .post('admin/auth/login', {
                    email: this.form.email,
                    password: this.form.password
                })
                .then(({ data }) => {
                    this.form.$busy = false
                    this.$store.commit('auth/setToken', data.data)
                    if (data.data.user.required_password_change === 1) {
                        this.changePasswordRequired = true
                        // this.$router.replace({ name: 'account.settings' })
                    } else {
                        this.$router.replace({ name: 'bindays' })
                    }
                })
                .catch(err => {
                    this.form.$busy = false
                    if (err.response.status === 401) {
                        this.form.$setErrors({
                            password: err.response.data.message
                        })
                    } else if (err.response.status === 422) {
                        this.form.$setErrors(err.response.data.errors)
                    } else if (err.response.status === 403) {
                        this.disabledAlert = true
                    }
                })
        },

        notYou() {
            this.clearForm()
        },

        alertOnClose() {
            this.disabledAlert = false
        },

        showemailTab() {
            this.tab = 'email-tab'
        },

        showPasswordTab() {
            this.tab = 'password-tab'
        },

        clearForm() {
            this.form.$reset()
            this.showemailTab()
        }
    },
    watch: {
        'form.email'() {
            this.form.$clearError('email')
        },
        'form.password'() {
            this.form.$clearError('password')
        }
    }
}
</script>
